<template>
  <div>Page is not found</div>
</template>

<script>
export default {
  name: 'NotFoundPage',
};
</script>

<style scoped></style>
